@import '../variables.scss';

body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.root {
  display: flex;
  width: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  justify-content: center;
  background-color: $grey2;
  font-family: $font-family;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}