// COLORS
$black: #202125;
$white: #FFFFFF;
$green: #5BCB02;
$grey1: #F8F8F8;
$grey2: #EEEEEE;
$grey3: #A1A2A4;

// FONTS
$font-family: 'Roboto', sans-serif;
$font-size-lg: 24px;
$font-size-md: 16px;
$font-size-sm: 12px;

